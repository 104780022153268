// Our google font of choice
@import url(https://fonts.googleapis.com/css2?family=Muli:wght@400;700&display=swap);

// MDShield colors
$mdshield-color-very-light-blue: #a9d6ff;
$mdshield-color-light-blue: #55a5ec;
$mdshield-color-medium-blue: #3c609c;
$mdshield-color-dark-blue: #18263e;
$mdshield-color-light-copper: #e6b269;
$mdshield-color-dark-copper: #9f7b4b;

// very dark copper used in shopify #7b5f3a;


// Bootsrap customizations
$font-family-sans-serif: 'Muli', sans-serif;
$enable-rounded: false;
//$link-color: #fff;
$body-bg: $mdshield-color-dark-blue;

// bootstrap
@import "~bootstrap/scss/bootstrap";



// our custom scss
header.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90px;
    background-color: $mdshield-color-medium-blue;

    div.logo {
        height:40px;
        margin: 16px;
        img {
            height: 100%;
        }
    }
}

nav.main {
    background-color: $mdshield-color-light-blue;
}

div.cu-break {
    padding: 2px 0;
    margin: 0;
    background-color: $mdshield-color-light-copper;
}

.navbar-nav {
    .nav-link {
        font-weight: bold;
    }
}

div.hero-container {
    display: flex;
    justify-content: center;

    .hero-border {
        flex-shrink: 1;
    }
}

div.hero {
    background: url(/assets/images/heros.jpg) no-repeat center center;
    background-size: cover;
    height: 550px;
    max-width: 1312px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: height 500ms ease;
}

@media (max-width: 575px) {
    div.hero {
        height: 400px;    //(550px / 776px) * 100vw;
        background-position-y: 70%;
    }
}
@media (max-width: 450px) {
    div.hero {
        height: 350px;    //(550px / 776px) * 100vw;
        background-position-y: 85%;
    }
}

footer.main {
    background-color: $mdshield-color-dark-blue;
    padding: 2rem 1rem;
    font-size: .8rem;

    span.link {
        padding: 0 2rem;
    }

    a {
        color: white;
    }
}

div.social-icons img {
    width: 32px;
    height: 32px;
    display: inline-block;
}

div.social-icons a:hover {
    text-decoration: none;
}

div.social-icons a {
    margin-left: 1rem;
    margin-right: 1rem;
}

// Layout used for legal text, e.g. privacy policy & terms of use
%legal-text {
    background-color: white;

    h4, h1 {
        color: $mdshield-color-medium-blue;
    }

    strong {
        color: $mdshield-color-medium-blue;
        font-weight: normal;
    }

    p {
        text-align: justify;
        text-justify: inter-word;
    }

    div.left-col {
        border-right: 1px solid $mdshield-color-light-copper;
    }
}

// privacy policy
article.privacy {
    @extend %legal-text;
}

// terms of use
article.terms-use {
    @extend %legal-text;
}


// contact form
div#modal-contact {
    h5 {
        font-weight: bold;
        color: $mdshield-color-medium-blue;
    }
}


// body slices
$slice-locked-width: 1024px;
$slice-block-size: 336px;
$slice-small-locked-width: ($slice-locked-width/1.5);
$slice-body-font-size: 20px;
$slice-body-line-spacing: 24px;
$slice-heading-font-size: 32px;
$slice-heading-line-spacing: 38px;


section.slice-container {
    padding: 32px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;

    // used upto BS small size (0-576)
    .slice-tile-text, .slice-tile-text-wide {
        background-color: red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        flex-shrink: 1;

        flex-basis: 100%;

        padding: 40px 0;
        // width: (($slice-block-size*2) / $slice-locked-width) *100vw;
        // height: ($slice-block-size / $slice-small-locked-width) *100vw;

        .text-frame {
            margin: 0;
            margin-right: (32px / $slice-small-locked-width) *100vw;
            margin-left: (32px / $slice-small-locked-width) *100vw;
        
            h2 {
                font-size: (64px / $slice-small-locked-width) *100vw;
                line-height: (80px / $slice-small-locked-width) *100vw;
                color: $mdshield-color-very-light-blue;
                padding: 0;
                margin: 0;
                margin-bottom: (12px / $slice-small-locked-width) *100vw;
                text-transform: uppercase;
            }
            p {
                padding: 0;
                margin: 0;
                font-size: (32px / $slice-small-locked-width) *100vw;
                line-height: (40px / $slice-small-locked-width) *100vw;
                font-weight: normal;
                color: white;
            }
            p.intro {
                padding: 0;
                margin: 0;
                font-size: (32px / $slice-small-locked-width) *100vw;
                line-height: (40px / $slice-small-locked-width) *100vw;
                font-weight: bold;
                color: white;
            }
            a {
                display: inline-block;
                margin: 16px 0 0 0;
            }
    
        }
        .text-frame-wide {
            margin-right: (64px / $slice-small-locked-width) *100vw;
            margin-left: (64px / $slice-small-locked-width) *100vw;
        }

    }
    .slice-tile-img, .slice-tile-img-wide {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        display: block;
        position: relative;
        // background-color: blue;
        height: (($slice-block-size*2) / $slice-small-locked-width) *100vw;

        img {
            // display: block;
            position: absolute;
            // position: relative;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: opacity 2500ms;
        }

        img.made-in-usa {
            left: unset;
            top: unset;
            right: 0;
            bottom: 0;
            width: 30%;
            height: 30%;
            object-fit: contain;
            animation: logo-pulse 2s infinite;
            transition: width 500ms, height 500ms;
        }

        img.made-in-usa:hover {
            width: 100%;
            height: 100%;
        }
    }
    .slice-tile-img-wide {
        height: ($slice-block-size / $slice-small-locked-width) *100vw;
    }


    .slice-tile-blank {
        display: none;
    }



    // BS Above small size
    @media (min-width: 576px) {

        .slice-tile-text, .slice-tile-text-wide {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 50%;
    
            padding: 0;

            // width: (($slice-block-size*2) / $slice-locked-width) *100vw;
            height: ($slice-block-size / $slice-small-locked-width) *100vw;
    
            .text-frame {
                margin: 0;
                margin-right: (32px / $slice-small-locked-width) *100vw;
                margin-left: (32px / $slice-small-locked-width) *100vw;
            
                h2 {
                    font-size: ($slice-heading-font-size / $slice-small-locked-width) *100vw;
                    line-height: ($slice-heading-line-spacing / $slice-small-locked-width) *100vw;
                    color: $mdshield-color-very-light-blue;
                    padding: 0;
                    margin: 0;
                    margin-bottom: (12px / $slice-small-locked-width) *100vw;
                }
                p {
                    margin: 0;
                    font-size: ($slice-body-font-size / $slice-small-locked-width) *100vw;
                    line-height: ($slice-body-line-spacing / $slice-small-locked-width) *100vw;
                }
                p.intro {
                    margin: 0;
                    font-size: (32px / $slice-small-locked-width) *100vw;
                    line-height: (40px / $slice-small-locked-width) *100vw;
                }
            }
            .text-frame-wide {
                margin-right: (64px / $slice-small-locked-width) *100vw;
                margin-left: (64px / $slice-small-locked-width) *100vw;
            }
        }
        .slice-tile-text-wide {
            flex-basis: 100%;
        }
        .slice-tile-img, .slice-tile-img-wide {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 50%;
            display: block;
            // max-width: $slice-block-size;
            // width: ($slice-block-size / $slice-locked-width) *100vw;
            height: ($slice-block-size / $slice-small-locked-width) *100vw;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
        }
        .slice-tile-blank {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 50%;
            display: block;
            height: ($slice-block-size / $slice-small-locked-width) *100vw;
        }
    }

    // BS size medium and above
    @media (min-width: 768px){
        .slice-tile-text, .slice-tile-text-wide {
            flex-grow: 1;
            flex-shrink: 1;
    
            max-width: $slice-block-size;
            flex-basis: 33.33%;
    
    
            // width: (($slice-block-size*2) / $slice-locked-width) *100vw;
            height: ($slice-block-size / $slice-locked-width) *100vw;
    
            .text-frame {
                margin: 0;
                margin-right: (32px / $slice-locked-width) *100vw;
                margin-left: (32px / $slice-locked-width) *100vw;
                
                h2 {
                    font-size: ($slice-heading-font-size / $slice-locked-width) *100vw;
                    line-height: ($slice-heading-line-spacing / $slice-locked-width) *100vw;
                    margin: 0;
                    margin-bottom: (12px / $slice-locked-width) *100vw;
                }
                p {
                    margin: 0;
                    font-size: ($slice-body-font-size / $slice-locked-width) *100vw;
                    line-height: ($slice-body-line-spacing / $slice-locked-width) *100vw;
                }
                p.intro {
                    margin: 0;
                    font-size: (32px / $slice-locked-width) *100vw;
                    line-height: (40px / $slice-locked-width) *100vw;
                }
            }
            .text-frame-wide {
                margin-right: (64px / $slice-locked-width) *100vw;
                margin-left: (64px / $slice-locked-width) *100vw;
            }

        }
        .slice-tile-text-wide {
            flex-grow: 2;
            flex-shrink: 2;
            max-width: $slice-block-size*2;
            flex-basis: 66.66%;
        }
        .slice-tile-img, .slice-tile-img-wide {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 33.33%;
            max-width: $slice-block-size;
            // width: ($slice-block-size / $slice-locked-width) *100vw;
            height: ($slice-block-size / $slice-locked-width) *100vw;
        }
        .slice-tile-img-wide {
            flex-grow: 2;
            flex-shrink: 2;
            flex-basis: 66.66%;
            max-width: $slice-block-size*2;
        }

        .slice-tile-blank {
            display: none;
        }
    }


    @media (min-width: 1024px) {
        .slice-tile-text, .slice-tile-text-wide {
   
            width: $slice-block-size*1;
            height: $slice-block-size;
    
            .text-frame {
                margin: 0 32px;
                
                h2 {
                    font-size: $slice-heading-font-size;
                    line-height: $slice-heading-line-spacing;
                    margin: 0 0 12px 0;
                }
                p {
                    font-size: $slice-body-font-size;
                    line-height: $slice-body-line-spacing;
                }
                p.intro {
                    font-size: 32px;
                    line-height: 40px;
                }
            }
            .text-frame-wide {
                margin: 0 64px;
            }
        }
        .slice-tile-text-wide {
            width: $slice-block-size*2;
        }

        .slice-tile-img, .slice-tile-img-wide {
            width: $slice-block-size;
            height: $slice-block-size;
            position: relative;
        }
        .slice-tile-img-wide {
            width: $slice-block-size*2;
        }
    }

    // colors for each style type
    .slice-type-intro.slice-tile-text, .slice-type-intro.slice-tile-text-wide {
        background-color: $mdshield-color-medium-blue;
    }
    .slice-type-intro.slice-tile-blank {
        background-color: $mdshield-color-dark-copper;
    }

    .slice-type-dark.slice-tile-text, .slice-type-dark.slice-tile-text-wide {
        background-color: $mdshield-color-dark-blue;
        h2 {
            color: white;
        }
    }
    .slice-type-dark.slice-tile-blank {
        background-color: $mdshield-color-light-copper;
    }

    .slice-type-regular.slice-tile-text, .slice-type-regular.slice-tile-text-wide {
        background-color: $mdshield-color-medium-blue;
    }
    .slice-type-regular.slice-tile-blank {
        background-color: $mdshield-color-dark-copper;
    }

    .slice-type-light.slice-tile-text, .slice-type-light.slice-tile-text-wide {
        background-color: $mdshield-color-light-blue;
        h2 {
            color: $mdshield-color-dark-blue;
        }
        p {
            color: $mdshield-color-dark-blue;
        }
    }
    .slice-type-light.slice-tile-blank {
        background-color: $mdshield-color-dark-copper;
    }

}

$pulse-small: .95;

@keyframes logo-pulse {
    0%
    {
      transform: scale( $pulse-small );
    }
    20%
    {
      transform: scale( 1 );
    }
    40%
    {
      transform: scale( $pulse-small );
    }
    60%
    {
      transform: scale( 1 );
    }
    80%
    {
      transform: scale( $pulse-small );
    }
    100%
    {
      transform: scale( $pulse-small );
    }
  }

.hackpad {
    height: 32px;
    background-color: white;
}

.discount {
    background-color: $mdshield-color-dark-copper;
    color: white;
}